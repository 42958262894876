import { CI } from ".";
import { Components } from "../../..";
import { Utils } from "../../../../utils";

export function QuestionnaireCiSteps(props) {
  const champs_demandes = props?.data?.useDemande?.champs_demande
    ? JSON.parse(props?.data?.useDemande?.champs_demande)
    : "{}";

  // console.log("champs_demandes",champs_demandes);
  const { type_demande } = props?.data?.useDemande;
  // const CODE_PAYS = props?.data?.useDemande.pays?.code ?? "";
  const LIBELLE_TYPE_DEMANDE = type_demande?.libelle
    ? type_demande.libelle.split(" ")[
        type_demande.libelle.split(" ").length - 1
      ]
    : "SARL";
  const QuestionnaireFormsByPaysByService = CI
    ? CI[LIBELLE_TYPE_DEMANDE]
    : CI.SARL;
  // console.log("LIBELLE_TYPE_DEMANDE]", LIBELLE_TYPE_DEMANDE);
  console.log("LIBELLE_TYPE_DEMANDE", LIBELLE_TYPE_DEMANDE);
  if (LIBELLE_TYPE_DEMANDE === "SARL") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStep
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "individuelle") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        {/* <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStep
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep> */}

        <QuestionnaireFormsByPaysByService.Associes
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          demande={LIBELLE_TYPE_DEMANDE}
        />

        {/* <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        /> */}

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        {/* <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        /> */}

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={4}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          demande={LIBELLE_TYPE_DEMANDE}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SARLU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSarlUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>
        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSarlUni
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSarlUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.AssociesSarlUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SASU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSasUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSasUni
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.AssociesSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={9}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "SAS") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={9}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );
  } else if (
    LIBELLE_TYPE_DEMANDE === "commerciale" ||
    LIBELLE_TYPE_DEMANDE === "civile"
  ) {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
        />

        {/* <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        /> */}

        <QuestionnaireFormsByPaysByService.RecapStepSci
          index={8}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          demande={LIBELLE_TYPE_DEMANDE}
          questionnaireObject={props.data.champs_questionnaireObject}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "Association") {
    // else  {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepAssociation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />
        <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          adresseNew={props.data.champs_questionnaireObject["adresse"] ?? "{}"}
        >
          <Components.OptionalStep
            isOptional={
              props.data.step === 3 &&
              champs_demandes["localisation-siege"]?.includes("Legafrik")
            }
            callback={() =>
              props.data.handleNextClick(new Event("click"), "adresse", "")
            }
          ></Components.OptionalStep>
        </QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation>
        <QuestionnaireFormsByPaysByService.ActiviteStepAssociation
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.PresidentAssociation
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.TresorierAssociation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
        />
        <QuestionnaireFormsByPaysByService.BureauAssociation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociationAdjoint
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.DureAssociation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.MontantAssociation
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.RecapStepAssopciation
          index={13}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } 
  else if (LIBELLE_TYPE_DEMANDE === "SCOOP") {
    // else  {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepAssociation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />
        <QuestionnaireFormsByPaysByService.AdresseOneStepAssociation
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          adresseNew={props.data.champs_questionnaireObject["adresse"] ?? "{}"}
        >
          <Components.OptionalStep
            isOptional={
              props.data.step === 3 &&
              champs_demandes["localisation-siege"]?.includes("Legafrik")
            }
            callback={() =>
              props.data.handleNextClick(new Event("click"), "adresse", "")
            }
          ></Components.OptionalStep>
        </QuestionnaireFormsByPaysByService.AdresseTwoStepAssociation>
        <QuestionnaireFormsByPaysByService.Associes
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
        />
        <QuestionnaireFormsByPaysByService.CapitalStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.ActiviteStepAssociation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.PresidentAssociation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.SecretaireGeneralAssociation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.TresorierAssociation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.BureauAssociation
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
        />
        <QuestionnaireFormsByPaysByService.CommissaireAssociationAdjoint
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          // nombreAssocies={champs_demandes["nombre-associes"]}
        />
        <QuestionnaireFormsByPaysByService.DureAssociation
          index={13}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.MontantAssociation
          index={14}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.RecapStepAssopciation
          index={15}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
          demande={LIBELLE_TYPE_DEMANDE}
        />
      </div>
    );
  } 
  else if (LIBELLE_TYPE_DEMANDE === "ONG") {
    // else  {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepOng
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepOng
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepOng
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>
        <QuestionnaireFormsByPaysByService.ActiviteStepOng
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.PresidentOng
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.SecretaireGeneralOng
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.TresorierOng
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
        />

        <QuestionnaireFormsByPaysByService.BureauOng
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CommissaireOng
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.CommissaireOngAjoint
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.DureOng
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.MontantOng
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.RecapStepOng
          index={13}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "Fondation") {
    // else  {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepFondation
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepFondation
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepFondation
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>
        <QuestionnaireFormsByPaysByService.ActiviteStepFondation
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        <QuestionnaireFormsByPaysByService.PresidentFondation
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.SecretaireGeneralFondation
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.TresorierFondation
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
        />

        <QuestionnaireFormsByPaysByService.BureauFondation
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CommissaireFondation
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.CommissaireFondationAdjoint
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.DureFondation
          index={11}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.MontantFondation
          index={12}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.RecapStepFondation
          index={13}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "Recouvrement") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepRecouvre
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["entreprise-debitrice"]}
        />

        <Components.OptionalStep
          isOptional={
            props.data.step === 2 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseOneStepRecouvre
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
          />
        </Components.OptionalStep>

        <Components.OptionalStep
          isOptional={
            props.data.step === 3 &&
            champs_demandes["localisation-siege"]?.includes("Legafrik")
          }
          callback={() =>
            props.data.handleNextClick(new Event("click"), "adresse", "")
          }
        >
          <QuestionnaireFormsByPaysByService.AdresseTwoStepRecouvre
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          />
        </Components.OptionalStep>

        {/* <QuestionnaireFormsByPaysByService.ActiviteStepRecouv
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        /> */}
        <QuestionnaireFormsByPaysByService.FacturesRecouvre
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CommentRecouvre
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />
        {/* <QuestionnaireFormsByPaysByService.SecretaireGeneralRecouvre
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.TresorierRecouvre
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
        />

        <QuestionnaireFormsByPaysByService.BureauRecouvre
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CommissaireRecouvre
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.DureRecouvre
          index={9}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
        <QuestionnaireFormsByPaysByService.MontantRecouvre
          index={10}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        /> */}
        <QuestionnaireFormsByPaysByService.RecapStepRecouvre
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          questionnaireObject={props.data.champs_questionnaireObject}
          handleSubmit={props.data.handleSubmit}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  } else if (LIBELLE_TYPE_DEMANDE === "statutaire") {
    return (
      <div className="grid grid-cols-12 mt-5">
        {/* <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}Sas
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
        /> */}

        <QuestionnaireFormsByPaysByService.AssociesStatutaire
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"][0]}
        />

        {/* <QuestionnaireFormsByPaysByService.AdresseTwoStepSas
            index={3}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={
              props.data.handleQuestionnaireBackClick
            }
            adresseNew={
              props.data.champs_questionnaireObject["adresse"] ?? "{}"
            }
          /> */}

        <QuestionnaireFormsByPaysByService.InfoAssemble
          index={2}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          type_demande={type_demande}
        />

        <QuestionnaireFormsByPaysByService.InfoModification
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          chamDemande={champs_demandes}
        />
        {/* <QuestionnaireFormsByPaysByService.PresidentStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        /> */}

        {/* <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

       
        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={8}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />
*/}
        <QuestionnaireFormsByPaysByService.RecapStepStatutaire
          index={4}
          step={props.data.step}
          handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(
            props.data.useDemande.champs_demande
          )}
          questionnaireObject={props.data.champs_questionnaireObject}
          dataGlobal={props.data ?? "[]"}
        />
      </div>
    );
  }

  return <div>Pas de questionnaire</div>;
}
